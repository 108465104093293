<template>
  <div class="home">
    <header class="header">
      <h2>湖南玖行信息科技有限公司</h2>
    </header>
    <el-carousel :interval="4000" height="441px">
      <el-carousel-item>
        <img src="../assets/banner1.png" alt="" srcset="" />
      </el-carousel-item>
      <el-carousel-item>
        <img src="../assets/banner2.png" alt="" srcset="" />
      </el-carousel-item>
    </el-carousel>
    <div class="desc">
      <h2>关于我们</h2>
      <p class="title">
        湖南玖行信息科技有限公司成立于近年,是一家集信息技术服务、企业管理咨询、生活服务等于一体的综合性科技公司。
      </p>
      <div>
        <p>公司主营业务包括:</p>
        <ul>
          <li>信息技术服务:提供信息技术咨询、技术开发、技术转让等服务。</li>
          <li>企业管理咨询:为企业提供管理咨询、商务代理等专业服务。</li>
          <li>
            生活服务业务:涵盖家政服务、停车服务、代驾服务、日用品销售等居民日常生活服务。
          </li>
          <li>其他业务:包括会议展览服务、财务咨询等。</li>
        </ul>
        <p>
          公司拥有互联网信息服务、餐饮服务、住宿服务等许可资质,致力于为客户提供便利、优质的综合性服务。
          公司秉承"科技引领生活"的经营理念,不断创新发展,努力成为湖南乃至全国领先的科技服务商。
        </p>
      </div>
    </div>
    <div class="footer">
      <div class="content">
        <p>地址：湖南省永州市新田县龙泉街道龙泉路盛和新城106号商铺</p>
        <p>
          Copyright © 2023-2024 hunanjiuxing.com All Rights Reserved.
          <a href="https://beian.miit.gov.cn" target="_blank"
            >湘ICP备2024076683号-1</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  // name: "Home"
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.logo {
  margin-top: 50px;
}
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  line-height: 20px;
  color: #999;
  background: #4b4b4b;
  a {
    text-decoration: none;
    color: #999;
  }
  .content {
    width: 800px;
    margin: 0 auto;
    text-align: left;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 120px;
}
.desc {
  text-align: left;
  margin-left: 120px;
  h2 {
    border-left: 4px solid #008858;
    padding-left: 8px;
    line-height: 28px;
  }
  .title {
    background-color: #008858;
    color: #fff;
    padding: 10px;
    margin-top: 20px;
    width: 850px;
    background-image: linear-gradient(to right, #008858 0%, #c6f3d7 100%);
  }
}
</style>
